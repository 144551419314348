.button {

    &.wide {
        padding-left:30px;
        padding-right:30px;
    }

    &.teens, &.blue, &.primary, &.green, &.red, &.orange, &.yellow {
        color:#fff;
        &:hover, &:focus {
            color:#fff;
        }
    }

    &.gray {
        color:#fff;
        &:hover {
            color:#fff;
        }
    }

    &.block, &.full {
        display:block;
        width:100%;
    }
}

.link-blue, .link-Blue {
    color:$blue;
    &:hover {
        color:$blue;
    }
}
.link-green, .link-Green {
    color:$green;
    &:hover {
        color:$green;
    }
}
.link-red, .link-Red {
    color:$red;
    &:hover {
        color:$red;
    }
}
