.elements__eventselement {

    .heading {
        margin-bottom:10px;
    }

    .featured-event {
        position: relative;
        border-radius:10px;
        overflow: hidden;
        display:block;
        transition: all .4s;
        min-height:500px;
        @include breakpoint(large) {
            min-height:0px;
        }

        img {
            border-radius:10px;
        }

        .background {
            position:absolute;
            background-size:cover;
            background-position:center;
            width:100%;
            height:100%;
            transition:transform .4s;
            z-index:0;
        }
        &:hover {
            .background {
                transform:scale(1.05);
            }
        }
        
        .content {
            position:absolute;
            width:100%;
            top:0px;
            z-index:1;
            display:flex;
            flex-direction: column;
            justify-content:flex-end;
            height:100%;
            background:rgba(0,0,0,.25);
            color:#fff;
            padding:20px;
            transition:background .4s;
            @include breakpoint(medium) {
                padding:30px;
            }
            @include breakpoint(large) {
                padding:36px;
            }

            .title {
                font-size:50px;
                line-height:52px;
                font-weight:500;
                margin-bottom:5px;
                color:#fff;
            }

            .description {
                width:calc(100% - 100px);
            }
            p {
                margin-bottom:5px;
                color:#fff;
            }

            .date-time {
                font-size:18px;
            }

        }
    }

    .upcoming-events-wrap {
        position: relative;
        height: calc(100% - 70px);
        overflow: hidden;
        margin-bottom: 10px;        
    }

    .upcoming-events-list {
        position: absolute;
        height: 100%;
        width: 100%;
        overflow: scroll;
    }

    .event-tile {
        display:block;
        border-radius:10px;
        padding:12px 0;
        transition:background .3s;
        color:#000;
        // &:hover {
        //     background:#F5F5F5;
        // }

        .event-tile-top {
            padding-bottom:10px;
        }
        .title {
            font-size:18px;
            margin-bottom:0px;
            font-weight:500;
        }
        .date-time {
            font-size:14px;
            color:rgba(0,0,0,.6);
            margin:0px;
        }
    }

    .slick-arrow {
        position:absolute;
        bottom:40px;
        z-index:9;
        color:#fff;
        width:21px;
        right:20px;
        transition: all .2s;
        @include breakpoint(medium) {
            bottom:50px;
        }

        svg {
            fill:#fff;
        }
    }

    .slick-prev {
        right:60px;
        @include breakpoint(medium) {
            right:80px;
        }
        @include breakpoint(large) {
            right:100px;
        }
        &:hover {
            transform:translateX(-5px);
        }
    }
    .slick-next {
        @include breakpoint(medium) {
            right:30px;
        }
        @include breakpoint(large) {
            right:50px;
        }
        &:hover {
            transform:translateX(5px);
        }
    }

}