#footer {
    background:#0B3F57;
    color:#fff;
    padding:43px 20px 30px;

    @include breakpoint(large) {
        padding:43px 0px 30px;
    }

    .footer-header {
        padding-bottom:35px;
    }
    .footer-header-right {
        @include breakpoint(medium) {
            text-align:right;

            .button {
                margin-left:10px;
            }
        }
    }

    .logo {
        display:block;
        margin-bottom:20px;
    }

    .social-links {
        display:inline-flex;
        align-items:center;
        a {
            font-size:18px;
            color:$gray;
            padding:0 20px 0 0;
            @include breakpoint(large) {
                padding:0 10px;
            }
        }
    }

    .footer-main {
        .cell {
            padding-bottom:20px;
            @include breakpoint(medium) {
                padding-bottom:30px;
            }
        }
        ul {
            display:inline-block;
            padding:0;
            margin:0;
            list-style:none;
            vertical-align:top;
            &:first-child {
                margin-right:40px;
                @include breakpoint(large) {
                    margin-right:80px;
                }
                @include breakpoint(xlarge) {
                    margin-right:100px;
                }
            }
        }
        a {
            color:#fff;
        }
    }

    .menu {
        // font-size:24px;

        a {
            display:block;
            padding-bottom:16px;
        }
    }

    .left-content {
        .footer-label {
            text-transform:uppercase;
            font-size:12px;
            font-weight:600;
            margin-bottom:4px;
            color:#fff;
        }
    
        p {color:rgba(255,255,255,.6);font-size:14px;}
        a {
            color:rgba(255,255,255,.6);
            text-decoration:none;
            &:hover {
                color:#fff;
            }
        }
    }

    .footer-legal-right {
        @include breakpoint(medium) {
            text-align:right;
        }
    }

    .legal {
        font-size:12px;
        color:rgba(255,255,255,.6);

        a {
            color:rgba(255,255,255,.6);
            text-decoration:underline;
            &:hover {
                color:#fff;
            }
        }
    }
}