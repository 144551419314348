.cta-content {
    padding:20px;
    border-radius:10px;
    background-size:cover;
    @include breakpoint(medium) {
        padding:30px;
        display:flex;
        justify-content:space-between;
        align-items:center;
    }
    &.Blue {
        background-color:$blue;
    }

    .title {
        font-size:30px;
        line-height:32px;
        font-weight:500;
        color:#fff;
        margin-bottom:20px;
        @include breakpoint(medium) {
            margin-bottom:0px;
        }
    }

}