#header {
    position:absolute;
    top:0px;
    width:100%;
    padding:20px 0;
    z-index: 10;
    transition:all .2s;
    @include breakpoint(large) {
        padding:0px;
    }

    &.solid, &.sticky {
        position:fixed;
    }

    &:before {
        content: '';
        position: absolute;
        left: 0px;
        top: 0px;
        width: 100%;
        background: #fff;
        height: 100%;
        z-index: 9;
    }

    .logo {
        display:none;
        width:30px;
        z-index:10;
        @include breakpoint(large) {
            display:inline-block;
            width:95px;
            position:relative;
        }
    }
    .mobile-logo {
        position: absolute;
        left: 0px;
        width:80px;
        @include breakpoint(large) {
            display:none;
        }
    }
    #btn-mobile-menu {
        padding-left: 50px;
    }
    .logo-dark {
        display:none;
    }
    .logo-light {
        display: inline-block;
    }

    .header-inner {
        display:flex;
        justify-content:space-between;
        align-items:center;

        .header-left {
            width:100%;
            @include breakpoint(large) {
                width:auto;
            }
        }

        .header-right {
            display:none;
            @include breakpoint(large) {
                display:block;
            }
            a:not(.button) {
                display:inline-block;
                padding:10px 15px;
                transform:translateY(4px);
            }

            path {
                fill:#fff;
            }
        }

        .button {
            margin-left:10px;
        }
    }

    &.solid, &.sticky {
        background:#fff !important;
        .header-right {
            position: relative;
            z-index: 10;
            svg path {fill:rgb(35, 31, 32);}
        }
        a.l1-link {
            color:#000;
            position:relative;
            z-index:10;
            transition:all .2s;
            &:hover {
                color: $red;
            }
        }

        .logo-dark {
            display:inline-block;
        }
        .logo-light {
            display:none;
        }
    }
    &.transparent {
        background:#fff;

        @include breakpoint(large) {
            background:transparent;
        }
        &:before {
            display: none;
        }
        .header-right {
            path {fill:#fff;}
        }
        .l1-link {
            color:#fff;
        }
    }
}