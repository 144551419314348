.banner {
    background-size:cover;
    background-position:center;
    position:relative;
    display:flex;
    justify-content:center;
    align-items:center;
    height:500px;
    color:#fff;
    text-align:center;

    h1,h2,h3,h4,h5,h6,p {
        color:#fff;
    }

    &.tint-light {
        &:before {
            content:'';
            position:absolute;
            width:100%;
            height:100%;
            background:rgba(0,0,0,0.2);
            z-index:0;
        }
    }
    &.tint-medium {
        &:before {
            content:'';
            position:absolute;
            width:100%;
            height:100%;
            background:rgba(0,0,0,0.4);
            z-index:0;
        }
    }
    &.tint-dark {
        &:before {
            content:'';
            position:absolute;
            width:100%;
            height:100%;
            background:rgba(0,0,0,0.6);
            z-index:0;
        }
    }

    > div {
        z-index:1;
    }
}