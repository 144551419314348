.elements__featuredcontent {

    .title {
        font-size:36px;
        line-height:38px;
        margin:0 0 2px;
    }
    .subtitle {
        font-size:28px;
        line-height:20px;
        @include breakpoint(medium) {
            font-size:28px;
            line-height:30px;
        }
    }
    .featured-content {
        background:#fff;
    }

    .featured-content__image {
        height:100%;
        background-position:center;
        background-size:cover;
        border-radius:10px;

        .image {
            display:block;
            width:100%;
            max-width:none;
            @include breakpoint(large) {
                display:none;
            }
        }
    }

    .featured-content__content {
        height:100%;
        display:flex;
        flex-direction:column;
        justify-content:space-between;
        max-width: 460px;
        padding-bottom:20px;
        @include breakpoint(large) {
            min-height: 500px;
            padding-bottom:0px;
        }
        h3 {
            margin-bottom:20px;
        }
    }

    .featured-content__button {
        margin-top:15px;
        @include breakpoint(medium) {
            margin-top:30px;
        }
    }

    // .image {
    //     min-height:100%;
    //     max-width:none;
    // }
}
