.desktop-nav {
    display:none;
    padding-left:15px;

    @include breakpoint(large) {
        display:inline-block;
    }
    ul {
        list-style-type: none;
        padding: 0;
        margin: 0;
        li {
            display: inline-block;
        }
        .l1-link {
            display: inline-flex;
            align-items:center;
            padding: 35px 10px;
            text-decoration: none;
            color: $body-dark;
            font-size:15px;
            @include breakpoint(xlarge) {
                font-size:18px;
                padding: 35px 15px;
            }

            .icon-angle {
                margin-left:5px;
                padding-top:2px;
            }
        }
    }
}


.mobile-nav {
    position:relative;
    z-index:10;
    display:flex;
    align-items:center;
    justify-content:space-between;
    width:100%;
    padding-left:60px;

    @include breakpoint(large) {
        display:none;
    }
}

.nav-tray {
    position: absolute;
    width: 100vw;
    background: #ffffff;
    padding: 100px 0 40px;
    z-index: 5;
    left: 50%;
    top: 0px;
    transform: translate(-50%, -100%);
    transition: all .7s;
    // opacity: 0;
    border-radius: 0 0 10px 10px;
    // box-shadow: 0 0 20px rgba(0,0,0,0.15);

    .tray-inner {
        max-width: 1110px;
        margin: 0 auto;
        display: flex;
        justify-content: space-between;
    }

    p {
        margin-bottom:10px;
    }

    ul {
        display:inline-block;
        padding-right:40px;
        vertical-align:top;
        li {
            display:block;
        }
        a {
            display: block;
            padding:7px 0px;
            font-size: 16px;
            color:#000;
            &:hover {
                color:$medium-blue;
            }
        }

        &:first-child {
            margin-bottom: 20px;
            a {
                font-size:18px;
                font-weight:500;
            }
        }
    }

    .l2-link {
        display: inline-flex;
        align-items:center;
        font-size: 18px;
        line-height: 20px;
        color: #000;
        width: calc(50% - 30px);
        padding: 10px 0px;
        border-radius: 10px;
        transition:all .2s;
        margin-right:20px;
        &:hover {
            background: #f5f5f5;
        }
        img {
            border-radius:8px;
            margin-right:20px;
        }
    }
    .tray-right {
        display:flex;
    }

    .tray-column {
        display:inline-block;
    }

    .tray-feature {
        padding-left:20px;

        &.feature-1 {
            width:100%;
            min-width:345px;
        }
        &.feature-2 {
            width:50%;
        }
        &.feature-3 {
            width:calc(100% / 3);
            max-width:230px;
        }
        .title {
            font-size:18px;
            font-weight:500;
            margin:10px 0 0;
        }
        .subtitle {
            color:rgba(0,0,0,.5);
        }

        img {
            border-radius:10px;
        }
    }
}

.desktop-nav {
    .l1-item {
        &:hover {
            .nav-tray {
                transform:translate(-50%, 0%);
                opacity: 1;
                box-shadow: 0 0 20px rgba(0,0,0,0.15);
            }
        }
    }
}
.side-navigation {
    .link {
        display:block;
        font-size: 16px;
        line-height:18px;
        color:#000;
        margin:20px 0;

        &.active {
            color:$medium-blue;
            padding-left:23px;
            border-left:2px solid $medium-blue;
        }
    }

}


.mobile-nav-wrap {
    position:relative;
    z-index:10;
    height:0px;
    transition:all .5s;
    overflow-y:auto;
    @include breakpoint(large) {
        display:none !important;
    }
    &.open {
        height:calc(100vh - 80px);
        padding:20px 0 80px;
    }

    .mobile-nav-inner {
        min-height:100%;
        height:auto;
        flex-direction: column;
        justify-content: space-between;
        display:flex;    
    }

    ul {
        list-style-type: none;
        padding: 0;
        margin: 0;
        li {
            display: block;
        }
        a {
            color: #000;
            display:block;
        }
    }
    .btn-close-parent-link {
        display:none;
        padding-right:20px;
    }
    .l1-link-mobile {
        display: inline-block;
        padding: 5px 0;
        font-size:50px;
        line-height:60px;
        font-weight:500;;
        color:#000;
    }

    .btn-mobile-tray-collapse {
        display:none;
        margin-right:10px;
    }

    .mobile-nav-tray {
        display:none;
        // max-height:0px;
        // overflow:hidden;
        // transition:all .5s;

        ul {
            margin-bottom: 30px;
            a {
                display: block;
                padding:2px 0;
                color: #000;
                font-size: 18px;
            }
    
            &:first-child {
                margin-bottom: 15px;
                a {
                    font-size:24px;
                    letter-spacing: -0.48px;
                }
            }
        }
    }

    .tray-open {
        .btn-close-parent-link {
            display:inline-block;
        }
        .mobile-nav-tray {
            padding-top:10px;
            // max-height:2000px;
        }
    }

    .l2-link {
        display:block;
        padding:6px 0;
        font-size:18px;
        font-weight:500;
        img {
            border-radius:8px;
            margin-right:20px;
        }
    }

    .tray-feature {
        margin-top:30px;

        img {
            width:100%;
        }

        .title {
            font-size:20px;
            font-weight:500;
            margin:10px 0 0;
        }
        .subtitle {
            font-size:16px;
            color:rgba(0,0,0,.5);
        }
    }

    .location-hours-wrap {
        margin-top:30px;
    }

    .location-hours {
        color:rgba(0,0,0,.6);
        font-size:14px;
        .menu-label {
            text-transform:uppercase;
            font-size:12px;
            font-weight:600;
            color:#000;
            margin:2px 0px;
        }
    }

    .buttons {
        margin-bottom:20px;
    }

    .button {
        margin:10px 0 0 !important;
    }
}