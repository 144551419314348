.elements__contentgrid {
    .grid-tile {
        position:relative;
        background:#F5F5F5;
        border-radius:10px;
        height:100%;
        display:block;

        @include breakpoint(medium) {
            padding-left:calc(30%);
        }
    }

    .grid-tile__image {
        background-size: cover;
        background-position: center;
        border-radius:10px;

        @include breakpoint(medium) {
            position: absolute;
            left: 0px;
            top: 0px;
            width: 30%;
            height: 100%;
        }

        img {
            border-radius:10px;
        }
    }

    .grid-tile__content {
        padding:20px;

        @include breakpoint(medium) {
            padding:20px 40px;
        }

        .title {
            font-size:24px;
            font-weight:500;
            color:#000;
        }

        p {
            font-size:14px;
            color:rgba(0,0,0,.7);
            @include breakpoint(large) {
                font-size:16px;
            }
        }
    }
}