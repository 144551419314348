.hero-carousel {
    height:calc(100vh - 160px);
    width:100%;
    position:relative;
    padding:0px !important;
    background:#000;
    overflow:hidden;
    border-radius:20px;
    @include breakpoint(medium) {
        height:500px;
    }

    .slick-list, .slick-track {
        height:100%;
    }

    .slick-dots {
        position:absolute;
        bottom: 110px;
        width:100%;
        z-index:4;
        margin:0px;
        padding:0px 20px;
        list-style-type:none;
        text-align:left;
        @include breakpoint(medium) {
            padding:0px;
            bottom: 50px;
            text-align:center;
        }
        @include breakpoint(large) {
            left: 50%;
            transform: translateX(-50%);
            max-width: 900px;
        }

        li {
            position:relative;
            cursor:pointer;
            opacity:.5;
            display:inline-block;
        }

        li:before {
            content: "";
            background: #fff;
            width: 90px;
            height: 1px;
            border-radius: 50%;
            display: block;
        }
        button {display:none;}

        .slick-active {
            opacity:1;
        }
    }

    .hero-carousel-item {
        position: relative;
        background-size:cover;
        background-position:center;
        background-repeat:no-repeat;
        height:100%;

        &:before {
            position: absolute;
            content: '';
            background: rgba(0,0,0,0);
            width: 100%;
            height: 100%;
            top: 0px;
            left: 0px;
            z-index: 1;
        }

    }

    // .slick-slider:not(.slick-initialized) .slick-slide:not(:first-of-type) .content {
    //     opacity:0;
    //     transform:translateY(100px);
    //     transition:all .3s;
    //     transition-delay:.7s;
    // }

    .content {
        position: relative;
        opacity:0;
        // transform:translateY(100px);
        transition:all .4s;
        transition-delay:.4s;
        text-align:left;
        padding:20px;
        display: flex;
        height:100%;
        flex-direction: column;
        justify-content: space-between;
        z-index:9;
        @include breakpoint(medium) {
            padding:30px;
        }
        @include breakpoint(large) {
            padding:50px 50px 40px;
        }
        
        .title {
            color:#fff;

            @include breakpoint(medium) {
                font-size:60px;
                line-height: 64px;
                margin-bottom:20px;
    
            }
        }
        p {
            font-size:24px;
            line-height: 28px;
            color:#fff;
            @include breakpoint(medium) {
                font-size:24px;
                line-height: 28px;
            }
        }
    }

    .slick-active .content {
        opacity:1;
        // transform:translateY(0px);
    }

    .title {
        color:#fff;
        font-size:48px;
        line-height: 52px;

        @include breakpoint(medium) {
            font-size:68px;
            line-height: 72px;
        }
    }


    .video {
        position:absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;

        @supports (object-position: center) {
            right: unset;
            bottom: unset;
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center;
        }

        &:before {
            content:'';
            position:absolute;
            top:0;
            left:0;
            width:100%;
            height:100%;
            background:rgba(0,0,0,0);
            z-index:1;
        }
    }

    .slick-arrow {
        position:absolute;
        bottom:40px;
        z-index:9;
        color:#fff;
        width:21px;
        right:20px;
        transition: all .2s;
        @include breakpoint(medium) {
            bottom:50px;
        }

        svg {
            fill:#fff;
        }
    }

    .slick-prev {
        right:60px;
        @include breakpoint(medium) {
            right:80px;
        }
        @include breakpoint(large) {
            right:100px;
        }
        &:hover {
            transform:translateX(-5px);
        }
    }
    .slick-next {
        @include breakpoint(medium) {
            right:30px;
        }
        @include breakpoint(large) {
            right:50px;
        }
        &:hover {
            transform:translateX(5px);
        }
    }

    .tint {
        content: '';
        background: black;
        width: 100%;
        height: 100%;
        left: 0px;
        position: absolute;
    }

}
