.elements__faqelement {
    padding:20px 0;
    @include breakpoint(medium) {
        padding:40px 0 30px;
    }

    .question-tile {
        padding:20px;
        border-radius: 10px;
        background:#F5F5F5;
        margin-bottom:10px;
        @include breakpoint(medium) {
            padding:30px;
        }

        .title {
            font-size: 24px;
            line-height:28px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin:0px;

            svg {
                transition: all .4s;
            }
        }

        .answer {
            max-height:0px;
            overflow: hidden;
            transition: all .4s;
            margin-top: 10px;
        }

        &.open {
            .title {
                color: #2A7699;
            }
            svg {
                transform: rotate(90deg);
            }
            .answer {
                max-height: 500px;
            }
        }
    }
}