.search-wrap {
    position: relative;
    display:inline-block;

    &.open {
        .search-bar {
            padding-left:70px;
            background-color: #fff;
            display: block;
        }

        #btn-open-search {
            visibility: hidden;
        }
    }
}

.search-bar {
    position: absolute;
    width: 100vw;
    height:99px;
    padding-top:26px;
    right: 0px;
    max-width: 900px;
    top: -27px;
    border: none;
    display:none;

    @include breakpoint(medium) {
        max-width: 400px;
    }
    @include breakpoint(large) {
        max-width: 520px;
    }
    @include breakpoint(xlarge) {
        max-width: 650px;
    }
    @include breakpoint(xxlarge) {
        max-width: 773px;
    }
}

.search-dropdown {
    position:absolute;
    right:0px;
    width:100px;
    font-size:14px;
    border:none;
    background:#ffffff url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTIiIGhlaWdodD0iMTIiIHZpZXdCb3g9IjAgMCAxMiAxMiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTUuNDYwOTQgOS41MzkwNkwwLjk2MDkzOCA1LjAzOTA2QzAuNjU2MjUgNC43NTc4MSAwLjY1NjI1IDQuMjY1NjIgMC45NjA5MzggMy45ODQzOEMxLjI0MjE5IDMuNjc5NjkgMS43MzQzOCAzLjY3OTY5IDIuMDE1NjIgMy45ODQzOEw2IDcuOTQ1MzFMOS45NjA5NCAzLjk4NDM4QzEwLjI0MjIgMy42Nzk2OSAxMC43MzQ0IDMuNjc5NjkgMTEuMDE1NiAzLjk4NDM4QzExLjMyMDMgNC4yNjU2MiAxMS4zMjAzIDQuNzU3ODEgMTEuMDE1NiA1LjAzOTA2TDYuNTE1NjIgOS41MzkwNkM2LjIzNDM4IDkuODQzNzUgNS43NDIxOSA5Ljg0Mzc1IDUuNDYwOTQgOS41MzkwNloiIGZpbGw9IiMyMzFGMjAiLz4KPC9zdmc+Cg==");
    background-position:center right 4px;
    background-repeat:no-repeat;
    z-index: 5;
    border-bottom: 1px solid #000;
    border-radius:0px;
    &:focus {
        outline:none;
        box-shadow: none;
        outline: none;
        border: none;
        border-bottom: 1px solid #000;
    }
}

.search-input {
    border: none;
    border-bottom: 1px solid #000;
    border-radius: 0px;
    box-shadow: none;
    margin:0px;
    &:focus {
        box-shadow: none;
        outline: none;
        border: none;
        border-bottom: 1px solid #000;
    }
}

#btn-close-search {
    position: absolute;
    left: 0px;
}


#mobile-search {
    position: absolute;
    left: 0px;
    background: #fff;
    top: 0px;
    width: 100%;
    height:100vh;
    z-index: 15;
    display: none;
    flex-direction: column;
    justify-content: space-between;
    padding-bottom: 100px;

    &.open {
        display: flex;
    }

    @include breakpoint(medium) {
        display: none;
    }

    .search-input {
        padding:40px 80px;
    }

    .search-icon {
        position: absolute;
        left: 0px;
        height: 80px;
        width: 80px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}

#btn-close-mobile-search {
    position: absolute;
    right: 0px;
    top: 0px;
    padding: 28px 20px;
}

.search-switch {
    position:relative;
    margin:0 auto;
    width:calc(100% - 30px);
    background:#F1F1F1;
    border-radius:25px;
    height:50px;
    display:flex;
    align-items:center;
    &.on {
        &::before {
            transform:translateX(100%);
            background-color:$blue;
        }
        .item-catalog {color:#000;}
        .item-website {color:#fff;}    
    }
    &:before {
        content:'';
        position:absolute;
        width:50%;
        height:100%;
        background:$red;
        border-radius:25px;
        transition:all 0.5s;
    }

    .search-switch-item {
        position:relative;
        display:inline-block;
        text-align: center;
        width:50%;
        color:#000;
    }
    .item-catalog {color:#fff;}
    .item-website {color:#000;}
}