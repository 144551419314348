.page-header {
    position:relative;
    display:flex;
    height:400px;
    background-size:cover;
    background-position:center;
    max-width:1500px;
    margin:0 auto 30px;
    border-radius:0 0 40px 40px;
    align-items: flex-end;
    background-color:#363636;
    border-radius: 0 0 40px 40px;
    overflow: hidden;

    &:before {
        content:'';
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0px;
        left: 0px;
        z-index:2;
    }

    &.tint-light:before {
        background: black;
        opacity:.2;
    }
    &.tint-medium:before {
        background: black;
        opacity:.4;
    }
    &.tint-dark:before {
        background: black;
        opacity:.6;
    }

    .grid-container {
        width:100%;
    }

    h1 {
        color:#fff;
        margin-bottom:20px;
        @include breakpoint(medium) {
            margin-bottom:30px;
        }
    }

    .page-header-content {
        z-index:3;
        position:relative;
    }
}