.newsletter-signup {
    margin-top:25px;
    margin-bottom:45px;

    .signup-inner {
        background:#FFEDCF;
        padding:30px 20px;
        border-radius: 10px;
        overflow: hidden;
        display:flex;
        flex-direction: column;
        justify-content:space-between;
        min-height:450px;
        @include breakpoint(medium) {
            padding:60px;
            display:block;
            min-height:0px;
        }
    }

    form {
        display:flex;
        flex-wrap:wrap;
        justify-content:space-between;
        .half {
            flex-grow:1;
            @include breakpoint(medium) {
                flex-grow:0;
            }
        }

        .button {
            display:block;
            width:100%;
            margin-top:30px;
            @include breakpoint(medium) {
                display:inline-block;
                width:auto;
                margin-top:0px;
            }
        }
    }

    input[type="email"] {
        width:100%;
        min-width:320px;
        padding:10px 10px 10px 0px;
        margin-bottom:10px;
        background:transparent;
        box-shadow:none;
        border:none;
        border-bottom:1px solid #000;
        border-radius:0px;
        @include breakpoint(medium) {
            min-width:490px;
        }
    }

    .checkbox-btn {
        display:inline-block;
        padding:0 12px;
    }

    .checkbox-btn span {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        width: 24px;
        height: 24px;
        margin-right:4px;
        background-color: #fff;
        transition: 0.3s;
    }
    
    .checkbox-btn span::before {
        content: '';
        display: inline-block;
        width: 15px;
        height: 8px;
        border-bottom: 3px solid #fff;
        border-left: 3px solid #fff;
        transform: scale(0) rotate(-45deg);
        position: relative;
        bottom: 2px;
        transition: 0.3s;
    }
    
    .checkbox-btn input {
        display: none;
    }
    
    .checkbox-btn input:checked ~ span {
        background-color: $yellow;
    }
    
    .checkbox-btn input:checked ~ span::before {
        transform: scale(1) rotate(-45deg);
    }
}

.ctct-form-container {
    .ctct-form-defaults {
        padding:0px !important;
        background:transparent !important;
    }

    .ctct-form-header {
        display:none;
    }

    #email_address_field_0 {
        .ctct-form-label {
            display:none;
        }
        .ctct-form-element {
            width:100%;
            font-size:16px;
            min-width:320px;
            padding:10px 10px 10px 0px;
            margin-bottom:10px;
            background:transparent;
            box-shadow:none;
            border:none;
            border-bottom:1px solid #000;
            border-radius:0px;
            font-family:'Poppins', sans-serif;
            &:focus {
                outline:none;
            }

            @include breakpoint(medium) {
                min-width:490px;
            }
        }
    }
    #email_address_field_0 {
        @include breakpoint(small down) {
            width: 100%;
        }
    }
    #list_memberships_label_0 {
        display:none;
    }

    fieldset.ctct-form-lists {
        display: flex;
        width: 50%;
        justify-content: flex-end;
        padding-top: 0px !important;
        padding-left: 0px !important;
        padding-right: 120px !important;
        margin:0px !important;
    }
    .ctct-form-listitem {
        display: inline-flex !important;
        white-space: nowrap;
        align-items: center;
        margin-right: 25px;

        @include breakpoint(medium) {
            margin-right:0px;
            margin-left: 25px;
        }

        .ctct-form-checkbox {
            position: relative;
            margin:0px;
            &:before {
                content:'';
                width: 22px;
                height: 22px;
                background:#fff;
                position: absolute;
                top: -3px;
                left: 0px;
            }

            &:checked:before {
                content: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA0NDggNTEyIj48cGF0aCBmaWxsPSIjZmZmZmZmIiBkPSJNNDM4LjYgMTA1LjRjMTIuNSAxMi41IDEyLjUgMzIuOCAwIDQ1LjNsLTI1NiAyNTZjLTEyLjUgMTIuNS0zMi44IDEyLjUtNDUuMyAwbC0xMjgtMTI4Yy0xMi41LTEyLjUtMTIuNS0zMi44IDAtNDUuM3MzMi44LTEyLjUgNDUuMyAwTDE2MCAzMzguNyAzOTMuNCAxMDUuNGMxMi41LTEyLjUgMzIuOC0xMi41IDQ1LjMgMHoiLz48L3N2Zz4=");
                background: $yellow;
                text-align: center;
                color: white;
                padding: 3px 4px 0px;
            }
        
        }
    }
    
    .ctct-form-checkbox-label {
        font-size:14px;
        font-family:'Poppins', sans-serif;
        padding-left: 15px !important;
        margin: 0px !important;
        color: black !important;        
    }

    button.ctct-form-button {
        background: $yellow !important;
        border: none !important;
        border-radius: 40px !important;
        font-size: 12px !important;
        font-family: 'Poppins', sans-serif !important;
        font-weight: 500;
        padding: 18px !important;

        @include breakpoint(medium) {
            position: absolute;
            top: 14px;
            right: 0px;
            width: 105px !important;
        }
    }
    #gdpr_text {
        display:none !important;
    }
}