.SearchResultsPage {
    .search-results {

        .result {
            margin-bottom: 20px;
            padding-bottom: 20px;
            border-bottom: 1px solid #d7d7d7;
            @include breakpoint(medium) {
                margin-bottom: 30px;
                padding-bottom: 30px;
            }
            &:last-child {
                border-bottom: none;
            }
        }
        .type {
            text-transform: uppercase;
            font-size:12px;
            line-height:14px;
            font-weight:500;
            margin-bottom:5px;
            letter-spacing:1px;
        }
        .title {
            margin-bottom:0px;
            a {
                color:$red;
                &:hover {
                    color: $red;
                    text-decoration:underline;
                }
            }
        }
        .description {
            margin-bottom:0px;
            p {
                margin-bottom:0px;
            }
        }
    }
}