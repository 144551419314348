.DatabaseCategoryPage {

    .database-item {
        margin:5px 0;
        .title {
            font-size:16px;
            margin-bottom:2px;
        }
        .description {
            color:rgba(0,0,0,0.6);
            font-size: 14px;
        }
        a {
            color:$medium-blue;
            text-decoration:underline;
            &:hover {
                color:$medium-blue;
            }
        }
    }
    

}