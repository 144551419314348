.pagination {
    display:flex;
    justify-content:center;
    align-items:center;
    text-align: center;
    color:$red;
    font-size:18px;
    margin:50px auto;
    @include breakpoint(medium) {
        margin:70px 0;
    }

    .fa {
        font-size: 12px;
        padding:0 10px;
    }

    .nav-btn {
        width:35px;
        display:inline-flex;
        justify-content:center;
        align-items:center;
        border-radius:5px;
        padding:2px 0px;
        color:$red;
        margin:0 7px;
    }

    .active {
        font-weight:900;
        background: #f5f5f5;
    }

    .prev-next {
        display:inline-flex;
        align-items:center;
        font-weight:900;
        margin:0 5px;
        color:$red;
    }

    .disabled {
        color:$red;
    }
}