.elements__grid {

    .grid-tile {
        position: relative;
        border-radius:10px;
        overflow:hidden;
        display:block;

        img {
            max-width:none;
            width:100%;
        }
    }

    .grid-tile__content {
        position: absolute;
        top:0px;
        left:0px;
        width:100%;
        height:100%;
        display:flex;
        flex-direction:column;
        align-items: flex-start;
        justify-content: flex-end;
        padding:20px;
        color:#fff;
        // background:rgba(0,0,0,.1);
        @include breakpoint(large) {
            padding:30px;
        }

        .title {
            font-size:24px;
            line-height:24px;
            font-weight:500;
            margin:0px 0px 5px;
        }
        .subtitle {
            line-height:20px;
            margin: 0px;
            color:rgba(255,255,255,.7);
        }
    }

    .stacked-layout {
        .title {
            font-weight:500;
            margin:10px 0;
            color:#000;
        }
        img {
            border-radius: 10px;
        }

        .slick-slider.grid-padding-x {
            margin-right: -15px;
            margin-left: -15px;
        }

        .slick-slide {
            padding-left:15px;
        }
    }

    .accent-layout {

        .cell {
            width:50%;
            @include breakpoint(medium) {
                width:calc(100% / 3);
            }
            @include breakpoint(large) {
                width:calc(25%);
            }
            @include breakpoint(xlarge) {
                width:calc(100% / 5);
            }
        }

        .slick-slider.grid-padding-x {
            margin-right: -15px;
            margin-left: -15px;
        }

        .slick-slide {
            padding-left:15px;
        }

        .grid-item-accent {
            display:flex;
            align-items:flex-start;
            justify-content:flex-start;
            position:relative;
            background:#F5F5F5;
            border-radius:10px;
            padding:20px;
            min-height:250px;
            height:100%;
            @include breakpoint(medium) {
                padding:30px;
            }
        }

        .title {
            font-size:24px;
            line-height:28px;
            color:#000;
            font-weight:500;
        }

        .accent-image {
            position: absolute;
            bottom: 0px;
            right: 0px;
        }
    }
}