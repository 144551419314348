.elements__contentelement {

        img {
            border-radius:10px;
            // width:100%;
        }

        ul, ol {
            li {
                padding-bottom:12px;
            }
        }
    
}