.elements__icongrid {
    .icon-grid {
        display:flex;
        flex-wrap:wrap;
        justify-content:space-evenly;
        @include breakpoint(medium) {
            align-items:center;
        }
    }
    .icon-grid-tile {
        text-align:center;
        padding:10px;
        width:calc(100% / 3);
        @include breakpoint(medium) {
            padding:20px;
        }
        @include breakpoint(large) {
            width:calc(100% / 6);
        }
        &:hover {
            .icon {
                transform:scale(1.07);
            }
        }
        .icon {
            display:inline-flex;
            justify-content:center;
            align-items:center;
            width:104px;
            height:104px;
            border-radius:50%;
            transition:transform 0.3s;
            @include breakpoint(medium) {
                width:120px;
                height:120px;
            }

            &.Red {
                background-color: $red;
            }
            &.Blue {
                background-color: $blue;
            }
            &.Green {
                background-color: $green;
            }
        }
        .title {
            color:#000;
            font-weight:500;
            line-height:16px;
            margin:15px 0;
        }
    }
}