.elements__databaseresources {

    nav {
        margin:25px 0 0;
        text-align:center;
        .button {
            color:$dark-blue;
            background-color:white;
            border:1px solid $dark-blue;
            font-size: 16px;
            padding: 12px 25px;
            margin:5px 2px;
            &:hover, &.active {
                color:white;
                background-color:$dark-blue;
            }
        }
    }

    .section-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height:90px;
        border-top:1px solid rgba(0,0,0,0.1);

        &.one, &.first {
            border-top:none;
        }
    }

    .database-list {
        display:none;
        border-bottom:1px solid rgba(0,0,0,0.1);
        &.open {
            display:block;
        }
    }

    .database-item {
        margin:5px 0;
        .title {
            font-size:16px;
            margin-bottom:2px;
        }
        .description {
            color:rgba(0,0,0,0.6);
            p, ul, ol {
                font-size:14px;
                color:rgba(0,0,0,0.6);
            }
        }
        a {
            color:$medium-blue;
            text-decoration:underline;
            font-weight:500;
            &:hover {
                color:$medium-blue;
                text-decoration:underline;
            }
        }
    
    }

    .btn-toggle-section {
        transition: all 0.3s;
        &.open {
            transform: rotate(90deg);
        }
    }

    .disclaimer {
        margin:40px 0 20px;
        background:#F5F5F5;
        padding:10px;
        border-radius:10px;
        font-size:14px;
        @include breakpoint(medium) {
            padding:20px;
        }

        p {
            color: rgba(0,0,0,0.6);
        }
    }


}