@charset 'utf-8';

@import 'settings';
@import 'foundation';
// @import 'motion-ui';
@import 'slick';
@import "fontawesome";
@import "regular";
@import "brands";

// Global styles
@include foundation-global-styles;
@include foundation-forms;
@include foundation-typography;

// Grids (choose one)
@include foundation-xy-grid-classes;
// @include foundation-grid;
// @include foundation-flex-grid;

// Generic components
@include foundation-button;
@include foundation-button-group;
@include foundation-close-button;
@include foundation-label;
// @include foundation-progress-bar;
// @include foundation-slider;
// @include foundation-range-input;
@include foundation-switch;
@include foundation-table;
// Basic components
// @include foundation-badge;
// @include foundation-breadcrumbs;
// @include foundation-callout;
// @include foundation-card;
// @include foundation-dropdown;
// @include foundation-pagination;
// @include foundation-tooltip;

// Containers
// @include foundation-accordion;
// @include foundation-media-object;
// @include foundation-orbit;
@include foundation-responsive-embed;
// @include foundation-tabs;
// @include foundation-thumbnail;
// Menu-based containers
// @include foundation-menu;
// @include foundation-menu-icon;
// @include foundation-accordion-menu;
// @include foundation-drilldown-menu;
// @include foundation-dropdown-menu;

// Layout components
// @include foundation-off-canvas;
@include foundation-reveal;
// @include foundation-sticky;
// @include foundation-title-bar;
// @include foundation-top-bar;

// Helpers
@include foundation-float-classes;
@include foundation-flex-classes;
@include foundation-visibility-classes;
// @include foundation-prototype-classes;

// Motion UI
// @include motion-ui-transitions;
// @include motion-ui-animations;

@import "./overrides.scss";

@import "./partials/header.scss";
@import "./partials/footer.scss";
@import "./partials/pageheader.scss";
@import "./partials/menu.scss";
@import "./partials/buttons.scss";
@import "./partials/search.scss";
@import "./partials/pagination.scss";

@import "./elements/hero.scss";
@import "./elements/featuredcontent.scss";
@import "./elements/grid.scss";
@import "./elements/contentgrid.scss";
// @import "./elements/contentelement.scss";
@import "./elements/newslettersignup.scss";
@import "./elements/faq.scss";
@import "./elements/bannerelement.scss";
@import "./elements/events.scss";
@import "./elements/icongrid.scss";
@import "./elements/bookfeed.scss";
@import "./elements/calltoaction.scss";
@import "./elements/databaseresources.scss";
@import "./elements/accordion.scss";
@import "./elements/content.scss";

@import "./pages/database.scss";
@import "./pages/search.scss";

.no-scroll {
    overflow:hidden;
}

.app-wrap {

    &.solid {
        padding-top:78px;
        @include breakpoint(large) {
            padding-top:100px;
        }
    }
}
.text-small {
    font-size:14px;
}
.text-medium {
    font-size: 18px;
}
.text-large {
    font-size: 24px;
}
.text-xlarge {
    font-size:32px;
}
.text-gray {
    color:#777777;
}
.heading-large {
    font-size:40px;
    line-height:42px;
    @include breakpoint(medium) {
        font-size:60px;
        line-height:62px;
    }
}
.heading-xlarge {
    font-size:60px;
    line-height:62px;
    @include breakpoint(medium) {
        font-size:80px;
        line-height:82px;
    }
}
.cms-edit-link {
    position:fixed;
    right:20px;
    bottom:120px;
    z-index:5;
}

.button {
    margin-bottom:0px;
}

.breadcrumbs {
    margin:20px 0;
    color:#777777;
    @include breakpoint(medium) {
        margin:25px 0;
    }

    a {display:inline-block;}
}

form {
    input[type="submit"] {
        @extend .button;
        @extend .wide;
        @extend .blue;
        color:#fff !important;
    }
}


// .lot-table {
//     tr {
//         display:flex;
//         background:#fff;
//         border:none;
//     }
//     td {
//         width:calc(100% / 3);
//     }
//     img {
//         border-radius:10px;
//     }
// }

.field.checkbox {
    position: relative;
    padding-left: 16px;
    margin-bottom:10px;
}

.field.checkbox .checkbox {
    position: absolute;
    left: 0px;
    top: 6px;
}

// Recaptcha Hack
.field[id*="CaptchaToken"] label {
    visibility: hidden;
    height: 0px;
}
.g-recaptcha {
    margin-bottom: 10px;
}