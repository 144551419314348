.grid-container.narrow {
    max-width: 1200px;
}
.d-flex {
    display:flex;
}

.reveal {
    width: 100%;
    max-width:800px;
    @include breakpoint(medium) {
        padding:20px 50px 50px;
    }
}

.slick-arrow {
    cursor: pointer;
}
.slick-dots {
    .slick-active:only-child {
        display:none;
    }
}

.reveal {
    border:none;

    .close-button {
        position:absolute;
        top:18px;
        right:18px;
    }
}



// Utility classes
.mt-0 {
    margin-top:0;
}
.mt-1 {
    margin-top:10px;
}
.mt-2 {
    margin-top:20px;
}
.mt-3 {
    margin-top:30px;
}
.mt-4 {
    margin-top:40px;
}
.mb-0 {
    margin-bottom: 0px;
}
.mb-1 {
    margin-bottom: 10px;
}
.mb-2 {
    margin-bottom: 20px;
}
.mb-3 {
    margin-bottom:30px;
}
