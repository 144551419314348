.elements__bookfeed {
    
    .book-feed-header {
        display:flex;
        justify-content:space-between;
        align-self:center;
    }

    .heading-wrap {
        @include breakpoint(medium) {
            max-width:calc(100% - 200px);
        }
        p {
            font-size:14px;
            @include breakpoint(medium) {
                max-width:calc(100% - 20px);
            }
        }
    }

    .slick-list {
        margin:0 -10px;
    }

    .slick-slide {
        padding:10px;
    }

    img {
        border-radius:10px;
    }

    .book-feed-nav {
        display:flex;
        align-items:center;
    }

    .slick-arrows {
        display:none;
        @include breakpoint(medium) {
            display:inline-flex;
            margin-left:30px;
        }
    }

    .slick-arrow {
        width:30px;
        padding-left:10px;
        svg {
            fill:#000;
        }
    }

    .link-view-all {
        font-size:14px;
    }
}