.elements__accordionelement {

    .section-header {
        display: inline-flex;
        width:100%;
        justify-content: space-between;
        align-items: center;
        height:90px;
        border-top:1px solid rgba(0,0,0,0.1);

        &.one {
            border-top:none;
        }
    }

    .accordion-item {
        display:none;
        border-bottom:1px solid rgba(0,0,0,0.1);
        &.open {
            display:block;
        }
    }

    .btn-toggle-section {
        transition: all 0.3s;
        &.open {
            transform: rotate(90deg);
        }
    }

    table.plain {
        tbody {
            display:flex;
            flex-wrap:wrap;
            border:none;
        }
        tr {
            background:#fff;
            border:none;
            width:calc(100% / 3);
            flex-wrap: wrap;
            display: flex;
            padding-bottom:20px;
        }
        td {
            padding:0px;
            width:100%;
            // text-align: center;
            a {
                font-size:18px;
                color:#000;
            }
        }
        img {
            border-radius:10px;
            // width:100%;
        }
    }
}